import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/kenneth/Sites/typebrigade/typebrigade.com/src/components/TemplateBlogPost.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "http://vancouverdesignwk.com"
      }}>{`Vancouver Design Week`}</a>{` is happening in September, and we’re excited to have Type Brigade be part of it with `}<a parentName="p" {...{
        "href": "http://walk.typebrigade.com/"
      }}>{`Type Walk`}</a>{` on the September 26th!`}</p>
    <p>{`The event costs only \$15 if you `}<a parentName="p" {...{
        "href": "http://walk.typebrigade.com/"
      }}>{`register before September 10th`}</a>{`.`}</p>
    <figure {...{
      "className": "figure figure--breakout"
    }}>
      <span parentName="figure" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1024px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/69f2eec5b84be176672142ef01147112/2bef9/blog-type-walk-1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "48.53515624999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAAsSAAALEgHS3X78AAACS0lEQVQoz2WP60tTcRjHz//Tm+aLygKJwGiFpIuSLmrKJEpRIaMIDcIIIyG3FYlpc21e0iZqupxt6ualjaUhMtFdbDvunO1sZ+ccz25nl9/vaQt61cPD993n+zwfIpIDxr3HN1wUmy7zjXLxrjyjlBfaFAXlpZyuPw+Ql6RCPo8AIwC8ZkZP6lDnTXSvCurOEgdBcn/FsjM1cWhd9CzOepYtfqs5Yp0//rEcsc7S+26vxxOhqLTXzbtW05sWcX1JcNqTX8fgTjnBCzw3OcxoXkjGocyX9/zSTOL7XEGngpkPYHyHvbulgzwLC3o89RYbB/H0AMwO46FXcE1GuPYOzM6tcYvNF4lNzH8bmzPZnFtiMglFBmP4fxAqJekD9VMiu2zCw325EVVO359f0B+LyWw6A8aPMNIHoypsUOFRNR5Tg74f9G9KqX0NBg2OhYvFhGtmenvSsK7XknaL4HEHgkdkkOR2t+kVM2kxRTft4k8Hs2E7tC6FHRtx1yZQARSlMSq+hYlfDoefZqJikgyF2DgX4nguIx0xMTrGhYvLCn4y7KPY3xEhREfjcb6k88+AYDvrEz0d2e77qc8DUkLM6FT53vaM5mHqZUf6eZvU0y4+axW6HiR6H4uBwyQCKZXKShL6a07A9VNw4zTIT4CmCwa7oaECbp+B5nJQyKC6DKpOQo0MK8rw9kYKwcqqbXzU8EmrXV+zl2DccrXQVImVV6C1Gh7VQHsN3KrAteeguRK3yKHxAq4/j2rLs05bGoBhYhRFBQLBOBsvwn8As/vTg3q51JcAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "blog type walk 1",
            "title": "blog type walk 1",
            "src": "/static/69f2eec5b84be176672142ef01147112/2bef9/blog-type-walk-1.png",
            "srcSet": ["/static/69f2eec5b84be176672142ef01147112/e17e5/blog-type-walk-1.png 400w", "/static/69f2eec5b84be176672142ef01147112/5a190/blog-type-walk-1.png 800w", "/static/69f2eec5b84be176672142ef01147112/2bef9/blog-type-walk-1.png 1024w"],
            "sizes": "(max-width: 1024px) 100vw, 1024px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>
      <figcaption parentName="figure">
        <a parentName="figcaption" {...{
          "href": "https://www.flickr.com/photos/kwl/"
        }}>{`Photo CC BY 2.0 Kenny Louie.`}</a>
      </figcaption>
    </figure>
    <p>{`Type Brigade is `}<a parentName="p" {...{
        "href": "http://meetup.com/typebrigade"
      }}>{`Vancouver’s type design and typography meetup`}</a>{`, where we host speakers every other month. This `}<a parentName="p" {...{
        "href": "http://walk.typebrigade.com"
      }}>{`Type Walk`}</a>{` is an off-month special event to coincide with Vancouver Design Week.`}</p>
    <h2>{`The Workshop`}</h2>
    <p><em parentName="p">{`6:00–8:00PM`}</em></p>
    <p>{`The first portion of the evening will be a workshop hosted by `}<a parentName="p" {...{
        "href": "http://workingformat.com"
      }}>{`Ross Milne of Working Format`}</a>{`. Ross studied type design at the internationally acclaimed Type & Media masters course at the Royal Academy of Art (KABK), is a contributing designer with Commercial Type in New York, and with Typotheque in the Hague, Netherlands.`}</p>
    <figure {...{
      "className": "figure"
    }}>
      <span parentName="figure" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "720px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/030efa6fe6b57a5493430057e35d3844/80e3c/blog-type-walk-workshop.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIEAQX/xAAUAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAG+ZcHpiif/xAAaEAADAAMBAAAAAAAAAAAAAAABAxEAAhIi/9oACAEBAAEFAhqeb7UauzEAv1CZn//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABoQAAIDAQEAAAAAAAAAAAAAAAARAQJhEjL/2gAIAQEABj8C6aLW0jCyFMpHux//xAAbEAEAAwADAQAAAAAAAAAAAAABABEhMUFRgf/aAAgBAQABPyFtx8EqdVGfIdTHCeS2zhSMabwcw71o3lT/2gAMAwEAAgADAAAAEHPf/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEB/9oACAEDAQE/EJGv/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEB/9oACAECAQE/ELWP/8QAHBABAQADAQADAAAAAAAAAAAAAREAIUGBYZHh/9oACAEBAAE/EBE4OgF95iChJHSVd4azUYHzhzF9Enu7v9xzjlTsn04JIuxAr5n/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "blog type walk workshop",
            "title": "blog type walk workshop",
            "src": "/static/030efa6fe6b57a5493430057e35d3844/80e3c/blog-type-walk-workshop.jpg",
            "srcSet": ["/static/030efa6fe6b57a5493430057e35d3844/066f9/blog-type-walk-workshop.jpg 400w", "/static/030efa6fe6b57a5493430057e35d3844/80e3c/blog-type-walk-workshop.jpg 720w"],
            "sizes": "(max-width: 720px) 100vw, 720px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>
      <figcaption parentName="figure">{`A previous type workshop by `}<a parentName="figcaption" {...{
          "href": "http://twitter.com/workingformat"
        }}>{`Working Format`}</a>{`.`}</figcaption>
    </figure>
    <p>{`This will take place at Chloi HQ, the `}<a parentName="p" {...{
        "href": "http://chloi.io"
      }}>{`Chloi Inc.`}</a>{` office.`}</p>
    <h2>{`The Walk`}</h2>
    <p><em parentName="p">{`8:30–9:30PM`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://www.google.ca/maps/place/55+E+Cordova+St,+Vancouver,+BC+V6A+1K3/@49.2824697,-123.1034229,17z"
      }}>{`Chloi HQ is in Gastown`}</a>{`, which makes it only a short walk to the second half of the evening: the walk! The Type Walk itself will be hosted by `}<a parentName="p" {...{
        "href": "http://christiandahlberg.com/"
      }}>{`Christian Dahlberg`}</a>{` of Vancouver Neon.`}</p>
    <h2>{`About Vancouver Design Week`}</h2>
    <p>{`Among other things, Vancouver Design Week is looking to`}</p>
    <blockquote>
      <p parentName="blockquote">{`cross-pollenate and empower designers and design-thinkers across disciplines … in Vancouver, and beyond.
`}<strong parentName="p">{`Vancouver Design Week`}</strong></p>
    </blockquote>
    <p>{`This is something particularly important to us with Type Brigade. Type has a place in most disciplines, and is a great way to start conversations about design.`}</p>
    <h2>{`Type Walk`}</h2>
    <p>{`Hope to see you on September 26th!`}</p>
    <a href="http://walk.typebrigade.com" className="button button--small">Join the Type Walk</a>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      